import { FC } from 'react';

import Preloader from '../preloader';

import styles from './styles.module.scss';

const PageLoader: FC = () => {
	return (
		<div className={styles.pageLoader}>
			<Preloader />
		</div>
	);
};

export default PageLoader;
