import { FC, ReactNode } from 'react';

import Footer from 'shared/ui/footer';
import Header from 'shared/ui/header';

import Navigation from 'widgets/navigation';

import './styles.scss';

type TProps = {
	children: ReactNode;
};

const BaseLayout: FC<TProps> = (props) => {
	const { children } = props;
	return (
		<div className="base-layout">
			<Header />
			<main className="main-content">
				<div className="container">
					<div className="row">
						<div className="col-md-3 col-xs-12">
							<Navigation />
						</div>
						<div className="col-md-9 col-xs-12">{children}</div>
					</div>
				</div>
			</main>
			<Footer />
		</div>
	);
};

export default BaseLayout;
