import { StateCreator } from 'zustand';

import { TSelectOption } from 'shared/ui/dropdown/dropdown';

export interface GenerateAllStatementsSlice {
	selectedValue: TSelectOption;
	setSelectedValue: (value: TSelectOption) => void;
	periods: TSelectOption[];
	setPeriods: (value: TSelectOption) => void;
	resetGenerateData: () => void;
	deletePeriod: (value: string) => void;
}

export const generateAllStatementsSlice: StateCreator<
	GenerateAllStatementsSlice,
	[],
	[],
	GenerateAllStatementsSlice
> = (set, get) => ({
	periods: null,
	selectedValue: null,
	setSelectedValue: (value) =>
		set({
			selectedValue: value,
		}),
	deletePeriod: (value) => {
		if (value) {
			const currentPeriods = get().periods;
			const newPeriods = currentPeriods?.filter((item) => item.value !== value);
			set({
				periods: newPeriods,
			});
		}
	},
	setPeriods: (value) => {
		if (value) {
			const currentPeriods = get().periods;
			const isPeriodExist = currentPeriods?.find((item) => item.value === value.value);
			if (isPeriodExist) return;
			set({
				periods: currentPeriods ? [...currentPeriods, value] : [value],
			});
		}
	},

	resetGenerateData: () =>
		set({
			periods: null,
			selectedValue: null,
		}),
});
