import classNames from 'classnames';
import { NavLink } from 'react-router-dom';

import styles from './styles.module.scss';

type TProps = {
	url: string;
	children: React.ReactNode;
	className?: string;
};

const NavigationLink = (props: TProps) => {
	const { url, children, className } = props;
	const linkClass = classNames('link', styles.link, className);

	if (!url) {
		return null;
	}

	return (
		<NavLink
			to={url}
			className={({ isActive }) => {
				return classNames(linkClass, {
					[styles.activeLink]: isActive,
				});
			}}
		>
			{children}
		</NavLink>
	);
};

export default NavigationLink;
