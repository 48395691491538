import { StateCreator } from 'zustand';

import { TSelectOption } from 'shared/ui/dropdown/dropdown';

export interface ClosePeriodFiltersSlice {
	selectedClosePeriod: TSelectOption;
	setClosePeriod: (value: TSelectOption) => void;
	resetCloseData: () => void;
}

export const closePeriodSlice: StateCreator<
	ClosePeriodFiltersSlice,
	[],
	[],
	ClosePeriodFiltersSlice
> = (set) => ({
	selectedClosePeriod: null,
	setClosePeriod: (value) =>
		set({
			selectedClosePeriod: value,
		}),

	resetCloseData: () =>
		set({
			selectedClosePeriod: null,
		}),
});
