import { useLocation } from 'react-router-dom';
import { usePrevious, useUpdateEffect } from 'react-use';

const ScrollToTop = () => {
	const location = useLocation();
	const prevLocation = usePrevious(location);
	useUpdateEffect(() => {
		if (location.pathname !== prevLocation.pathname) {
			window.scrollTo(0, 0);
		}
	}, [location, prevLocation]);

	return null;
};

export default ScrollToTop;
