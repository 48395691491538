import { createQueryKeyStore } from '@lukemorales/query-key-factory';
import { AxiosResponse } from 'axios';

import axios from 'app/axios';

import { TPagination, TPaginationParams } from './base';
import { TEditionReportValue, TEditions } from './editions';
import { TRoyaltyPeriodStatusType, TRoyaltyPeriodType } from './reports';

export const statementsKeyStore = createQueryKeyStore({
	statements: {
		table: (params: TRoyaltyStatementsParams) => [
			params.page,
			params.size,
			params.searchTerm,
			params.beneficiaryDeliveryOption,
			params.year,
			params.royaltyPeriodType,
			params.sort,
		],
		byAuthorId: (authorId: number, values: TStatementParams) => [authorId, values],
		varifyStatements: null,
		createStatements: null,
	},
});

export type TStatementsBYAuthor = {
	statements: TStatement[];
};

export type TStatement = {
	date: string;
	isbn: string;
	qty: number;
	percent: number;
	royaltyType: string;
	royalty: number;
	sales: string;
	salesType: string;
};
export type TStatementFilters = {
	'from.royaltyPeriodType': string;
	'from.year': string;
	'to.royaltyPeriodType': string;
	'to.year': string;
};

export type TStatementParams = TStatementFilters;

export const getRoyaltyStatementByAuthorId = async (
	authorId: string | number,
	values: TStatementParams,
): Promise<TStatementsBYAuthor> => {
	const { data } = await axios.get<TStatementsBYAuthor>(`/statements/${authorId}`, {
		params: values,
	});
	return data;
};

export type TPeriodParams = {
	royaltyPeriodType: TRoyaltyPeriodType;
	year: string;
};

export const createAllRoyaltyStatements = async (values: TPeriodParams): Promise<void> => {
	const { data } = await axios.post<void>('/statements/files', values);
	return data;
};

export type TVerifyStatementsGeneration = 'IN_PROGRESS' | 'COMPLETED' | 'FAILED';

export type TRoyaltyStatement = {
	advanceRemaining: number;
	authorId: number;
	beneficiary: string;
	beneficiaryEmail: string;
	calculatingNow: true;
	royaltiesEarned: number;
	royaltiesPayable: number;
	royaltyPeriod: string;
	statementId: number;
	title: string;
	year: number;
};

export type TRoyaltyStatements = { statements: TPagination & { content: TRoyaltyStatement[] } };

export const verifyRoyaltyStatementGeneration = async (
	values: TPeriodParams,
): Promise<TVerifyStatementsGeneration> => {
	const { data } = await axios.get<TVerifyStatementsGeneration>('/statements/verify', {
		params: values,
	});
	return data;
};

export type TRoyaltyStatementsFilters = {
	searchTerm?: string;
	beneficiaryDeliveryOption: string;
	royaltyPeriodType: TRoyaltyPeriodType;
	year: string;
};

export type TRoyaltyStatementsParams = TRoyaltyStatementsFilters & TPaginationParams;

export const getRoyaltyStatements = async (
	params: TRoyaltyStatementsParams,
): Promise<TRoyaltyStatements> => {
	const { data } = await axios.get<TRoyaltyStatements>(`/statements`, { params });
	return data;
};

export type TGenerateStatementFile = TRoyaltyStatementsFilters;
export const generateStatementFile = (
	params: TRoyaltyStatementsParams,
): Promise<AxiosResponse<BlobPart>> => {
	return axios.get<BlobPart>(`/statements/file`, {
		params,
		responseType: 'blob',
	});
};
