import './styles.scss';

const Footer = (): JSX.Element => {
	return (
		<div className="footer">
			<div className="container">
				<div className="row">
					<div className="col-sm-4 col-xs-12">
						<div className="footer__copy">
							<p>
								<strong>© Watkins Media Limited {new Date().getFullYear()}</strong>
							</p>
							<p>All rights reserved</p>
						</div>
					</div>

					<div className="col-sm-8 col-xs-12">
						<p className="footer__version">
							Minerva Royalties {process.env.REACT_APP_VERSION || ''}
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Footer;
