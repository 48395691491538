import { FC, ReactElement } from 'react';

import classNames from 'classnames';

import './styles.scss';

type TProps = {
	children?: ReactElement | ReactElement[];
	dataCy?: string;
	className?: string;
};

const Frame: FC<TProps> = (props) => {
	const { children, dataCy, className } = props;
	const classEl = classNames('frame', className);

	return (
		<section className={classEl} data-cy={dataCy}>
			{Array.isArray(children) ? children.map((child) => child) : children}
		</section>
	);
};

export default Frame;
