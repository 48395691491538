import { FC, ReactNode } from 'react';

import classNames from 'classnames';

type TProps = {
	children: ReactNode;
	className?: string;
};

const FrameBody: FC<TProps> = (props) => {
	const { children, className } = props;
	const classEl = classNames('frame__body', className);
	return <div className={classEl}>{children}</div>;
};

export default FrameBody;
