import { createQueryKeyStore } from '@lukemorales/query-key-factory';

import axios from 'app/axios';

type TGetCloudFileReportById = {
	url: string;
};

export const cloudFilesKeyStore = createQueryKeyStore({
	cloudFile: {
		getCloudFile: (cloudFileId: number) => [cloudFileId],
	},
});

export const getCloudFileReportById = async (
	cloudFileId: number,
): Promise<TGetCloudFileReportById> => {
	const { data } = await axios.get<TGetCloudFileReportById>(`/cloud-files/${cloudFileId}`);
	return data;
};
