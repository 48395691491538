import { FC } from 'react';

import classNames from 'classnames';
import { useToggle } from 'react-use';

import { TNavigationButton } from '../../types';
import NavigationItem from '../navigation-item';
import NavigationLink from '../navigation-link';

import styles from './styles.module.scss';

type TProps = {
	text: string;
	dropdown: TNavigationButton[];
};
const NavigationItemDropdown: FC<TProps> = (props) => {
	const { text, dropdown } = props;

	const [toggle, setToggle] = useToggle(true);

	const classDropdown = classNames(styles.dropdown, {
		[styles.dropdownOpen]: toggle,
	});

	const classIcon = classNames(styles.icon, 'icon-chevron-down', {
		[styles.iconRotate]: toggle,
	});

	const handlerToggle = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		event.preventDefault();
		setToggle(!toggle);
	};

	return (
		<li className={styles.item}>
			<button className={styles.toggle} type="button" onClick={handlerToggle}>
				{text} <span className={classIcon} />
			</button>
			<ul className={classDropdown}>
				{dropdown.map((item) => {
					return (
						<NavigationItem key={item.url}>
							<NavigationLink url={item.url} className={styles.subLink}>
								{item.text}
							</NavigationLink>
						</NavigationItem>
					);
				})}
			</ul>
		</li>
	);
};

export default NavigationItemDropdown;
