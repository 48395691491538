import { FC, ReactElement } from 'react';

import classNames from 'classnames';

type TProps = {
	title?: string;
	buttons?: ReactElement;
	leftSide?: ReactElement;
	titleClass?: string;
	dataCy?: string;
	customClass?: string;
	buttonsClass?: string;
};

const FrameHeader: FC<TProps> = (props) => {
	const { title, buttons, leftSide, titleClass, dataCy, customClass, buttonsClass } = props;
	const classHeader = classNames('frame__header', customClass);
	const btnClass = classNames('frame__buttons', buttonsClass);

	return (
		<div className={classHeader} data-cy={dataCy}>
			<div className={titleClass}>
				{leftSide}
				{title && <span className="frame__title">{title}</span>}
			</div>
			{buttons && <div className={btnClass}>{buttons}</div>}
		</div>
	);
};

export default FrameHeader;
