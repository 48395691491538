import { useEffect, useRef } from 'react';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import differenceBy from 'lodash/differenceBy';
import { ToastOptions, toast } from 'react-toastify';
import { usePrevious } from 'react-use';
import { useShallow } from 'zustand/react/shallow';

import { TPeriodParams, reportPeriodsKeyStore } from 'shared/api/report-periods';
import { TRoyaltyPeriodType } from 'shared/api/reports';
import {
	TVerifyStatementsGeneration,
	createAllRoyaltyStatements,
	statementsKeyStore,
	verifyRoyaltyStatementGeneration,
} from 'shared/api/statements';
import useAdminStore from 'shared/store/admin-store';

import './style.scss';

import CompletedMessage from './ui/completed-message';
import WaitingMessage from './ui/waiting-message';

const GenerateStatements = () => {
	const pollingRef = useRef({});

	const client = useQueryClient();

	const { periods, deletePeriod } = useAdminStore(
		useShallow((state) => ({
			periods: state.periods,
			deletePeriod: state.deletePeriod,
		})),
	);

	const { mutateAsync: verifyPeriod } = useMutation<
		TVerifyStatementsGeneration,
		unknown,
		TPeriodParams
	>({
		mutationKey: [statementsKeyStore.statements.varifyStatements],
		mutationFn: (values) => verifyRoyaltyStatementGeneration(values),
	});

	const { mutateAsync: createStatement } = useMutation<any, unknown, TPeriodParams>({
		mutationKey: [statementsKeyStore.statements.createStatements],
		mutationFn: (values) => createAllRoyaltyStatements(values),
	});

	const prevPeriods = usePrevious(periods);

	const generateCompeteToastId = (type, year) => `${type}-${year}-completed`;
	const generateWaitingToastId = (type, year) => `${type}-${year}-waiting`;

	const toastParams: ToastOptions = {
		position: 'bottom-right',
		hideProgressBar: true,
		closeOnClick: false,
		className: 'generate-statements-toast',
	};

	const startPolling = async ({
		year,
		royaltyPeriodType,
		key,
		waitingToastId,
	}: {
		year: string;
		royaltyPeriodType: TRoyaltyPeriodType;
		key: string;
		waitingToastId: string;
	}) => {
		if (pollingRef.current[key]) {
			return;
		}
		pollingRef.current[key] = setInterval(async () => {
			const data = await verifyPeriod({ year, royaltyPeriodType });
			if (data === 'COMPLETED') {
				clearInterval(pollingRef.current[key]);
				delete pollingRef.current[key];
				deletePeriod(key);
				toast.dismiss(waitingToastId);
				toast(
					(props) => {
						return (
							<CompletedMessage
								year={year}
								royaltyPeriodType={royaltyPeriodType}
								closeToast={props.closeToast}
							/>
						);
					},
					{
						...toastParams,
						autoClose: 10000,
						theme: 'light',
						toastId: generateCompeteToastId(royaltyPeriodType, year),
					},
				);
				return;
			}
			if (data === 'FAILED') {
				clearInterval(pollingRef.current[key]);
				delete pollingRef.current[key];
				deletePeriod(key);
				toast.dismiss(waitingToastId);
				toast.error('Statement generation failed', {
					...toastParams,
					type: 'error',
					theme: 'colored',
					autoClose: 10000,
				});
			}
		}, 10000);
		return;
	};

	const startGenerateStatements = async () => {
		const values = differenceBy(periods, prevPeriods, 'value');
		if (!values.length) return;
		let waitingToastId = null;
		try {
			const newPeriod = values[0];
			const [year, period] = (newPeriod?.value || '').split(',') as [string, TRoyaltyPeriodType];
			waitingToastId = generateWaitingToastId(period, year);
			// close completed toast after start generating new statement
			toast.dismiss(generateCompeteToastId(period, year));
			toast(<WaitingMessage />, {
				...toastParams,
				toastId: waitingToastId,
				theme: 'light',
			});
			// start creating statement
			await createStatement({ year, royaltyPeriodType: period });

			// check statement file, if exist Download button will be shown
			await client.refetchQueries({
				queryKey: [
					reportPeriodsKeyStore.reportPeriod.getReportPeriod({ year, royaltyPeriodType: period }),
				],
			});
			return startPolling({
				year,
				royaltyPeriodType: period,
				key: newPeriod?.value,
				waitingToastId,
			});
		} catch (e) {
			const message = e?.response?.data?.message || 'Statement generation failed';
			const key = values?.[0]?.value;
			toast.dismiss(waitingToastId);
			clearInterval(pollingRef.current[key]);
			delete pollingRef.current[key];
			deletePeriod(key);
			toast.error(message, {
				...toastParams,
				type: 'error',
				theme: 'colored',
				autoClose: 10000,
			});
		}
	};

	useEffect(() => {
		// check new period
		const isHasNewPeriod =
			periods?.length > prevPeriods?.length || (periods?.length && !prevPeriods?.length);
		if (isHasNewPeriod) {
			startGenerateStatements();
		}
	}, [periods, prevPeriods]);

	return null;
};

export default GenerateStatements;
