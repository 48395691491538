import Axios from 'axios';

const API_URL = process.env.REACT_APP_API_HOST;

export const axios = Axios.create({
	baseURL: API_URL,
	withCredentials: true,
});

axios.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => {
		console.log(error);
		if (error.response?.status === 401 && error.response?.data?.targetUrl) {
			window.location.href = error.response?.data?.targetUrl;
			return;
		}
		return Promise.reject(error);
	},
);

export { axios as default, API_URL };
