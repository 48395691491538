import { create } from 'zustand';

import { closePeriodSlice } from 'widgets/admin/close-period/slice/close-period-slice';
import { generateAllStatementsSlice } from 'widgets/admin/generate-all-statements/slice/generate-all-statements-slice';

const useAdminStore = create<
	ReturnType<typeof closePeriodSlice> & ReturnType<typeof generateAllStatementsSlice>
>((...a) => ({
	...closePeriodSlice(...a),
	...generateAllStatementsSlice(...a),
}));

export default useAdminStore;
