import React, { FC } from 'react';

import classNames from 'classnames';

import Preloader from '../preloader';

import './styles.scss';

export interface IButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	className?: string;
	shape?: 'square' | 'circle';
	onClick?: (value: any) => any;
	styles?: 'primary' | 'danger' | 'info' | 'success';
	loading?: boolean;
	outline?: boolean;
	dataCy?: string;
}

const Button: FC<IButtonProps> = (props) => {
	const {
		type = 'button',
		children,
		className,
		styles = 'primary',
		onClick,
		shape,
		disabled,
		loading,
		outline,
		dataCy,
	} = props;

	const handlerClick = (event) => {
		if (!disabled && onClick) {
			onClick(event);
		}
	};

	const theme = outline ? { [`btn_${styles}-outline`]: outline } : { [`btn_${styles}`]: styles };
	const classEl = classNames('btn', {
		...theme,
		[`btn_${shape}`]: shape,
		[`btn_loading`]: loading,
		[`${className}`]: className,
	});

	return (
		<button
			type={type}
			className={classEl}
			onClick={handlerClick}
			disabled={disabled}
			data-cy={dataCy}
		>
			<span className="btn__text">
				<>{children}</>
			</span>
			{loading && <Preloader size={12} borderWidth={2} />}
		</button>
	);
};

export default Button;
