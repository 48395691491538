import { useQuery } from '@tanstack/react-query';

import { getMe, usersKeyStore } from 'shared/api/users';

const GetUserPermissions = () => {
	useQuery({
		queryKey: [usersKeyStore.user.me],
		queryFn: getMe,
	});

	return null;
};

export default GetUserPermissions;
