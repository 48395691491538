import React, { useMemo } from 'react';

import { useMutation, useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { useToggle } from 'react-use';

import { getMe, logout, usersKeyStore } from 'shared/api/users';

import logo from 'assets/images/logo.svg';

import styles from './styles.module.scss';

const Header = (): JSX.Element => {
	const [show, toggleClick] = useToggle(false);

	const { data } = useQuery({
		queryKey: [usersKeyStore.user.me],
		queryFn: getMe,
	});

	const isAdmin = data?.data?.authority === 'ROLE_ADMIN';

	const links = useMemo(() => {
		if (isAdmin) {
			return [
				{
					href: `${process.env.REACT_APP_DOMAIN}/search`,
					label: 'Home',
				},
				{
					href: `${process.env.REACT_APP_DOMAIN}/admin/user/list`,
					label: 'Users',
				},
				{
					href: `${process.env.REACT_APP_DOMAIN}/admin/imprint/list`,
					label: 'Imprints',
				},
				{
					href: `${process.env.REACT_APP_DOMAIN}/distribution/log`,
					label: 'Distribution',
				},
				{
					href: `${process.env.REACT_APP_DOMAIN}/rights`,
					label: 'Rights',
				},
				{
					href: `${process.env.REACT_APP_DOMAIN}/royalties`,
					label: 'Royalties',
				},
				{
					href: `${process.env.REACT_APP_DOMAIN}/notifications`,
					label: 'Notifications',
				},
			];
		}
		return [
			{
				href: `${process.env.REACT_APP_DOMAIN}/search`,
				label: 'Home',
			},
			{
				href: `${process.env.REACT_APP_DOMAIN}/distribution/log`,
				label: 'Distribution',
			},
			{
				href: `${process.env.REACT_APP_DOMAIN}/rights`,
				label: 'Rights',
			},
			{
				href: `${process.env.REACT_APP_DOMAIN}/royalties`,
				label: 'Royalties',
			},
			{
				href: `${process.env.REACT_APP_DOMAIN}/notifications`,
				label: 'Notifications',
			},
		];
	}, [isAdmin]);

	const { isPending, mutateAsync } = useMutation({
		mutationKey: [usersKeyStore.user.logout],
		mutationFn: logout,
	});

	const classMenu = classNames(styles.nav, {
		[styles.open]: show,
	});

	const signOutHelper = async (event): Promise<void> => {
		event.preventDefault();
		if (!isPending) {
			const response = await mutateAsync();
			if (response?.data?.targetUrl) {
				window.location.href = response?.data?.targetUrl;
			}
		}
	};

	return (
		<header className={styles.header}>
			<div className="container">
				<div className={styles.header__wrap}>
					<strong className={styles.logo}>
						<Link to="/">
							<img src={logo} alt="Minereva logo" />
						</Link>
					</strong>

					<button type="button" className={styles.navbarToggle} onClick={toggleClick}>
						<span className={styles.navbarToggle__line} />
						<span className={styles.navbarToggle__line} />
						<span className={styles.navbarToggle__line} />
					</button>
					<ul className={classMenu}>
						{links.map((item) => (
							<li className={styles.nav__item} key={item.href}>
								<a href={item.href} className={styles.nav__link} target="_blank" rel="noreferrer">
									{item.label}
								</a>
							</li>
						))}
						<li className={styles.nav__item}>
							{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
							<span className={styles.nav__link} onClick={signOutHelper}>
								Sign out
							</span>
						</li>
					</ul>
				</div>
			</div>
		</header>
	);
};

export default React.memo(Header);
