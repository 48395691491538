import './styles.scss';

type TProps = {
	size?: number;
	color?: string;
	borderWidth?: number;
};
const Preloader = (props: TProps): JSX.Element => {
	const { size = 24, color = '#337ab7', borderWidth = 2 } = props;
	return (
		<div className="preloader" style={{ width: size, height: size }}>
			<div className="preloader__inner">
				<div
					style={{
						borderColor: color,
						borderWidth: borderWidth,
						borderTopColor: 'transparent',
					}}
				/>
			</div>
		</div>
	);
};

export default Preloader;
