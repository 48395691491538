import { createQueryKeyStore } from '@lukemorales/query-key-factory';

import axios from '../../app/axios';

import { TRoyaltyPeriodType } from './reports';

export const reportPeriodsKeyStore = createQueryKeyStore({
	reportPeriod: {
		getReportPeriod: (params: TPeriodParams) => [params.year, params.royaltyPeriodType],
	},
});

export type TPeriodParams = {
	royaltyPeriodType: TRoyaltyPeriodType;
	year: string;
};

type TReportPeriod = {
	cloudFileId: number;
	id: number;
	royaltyPeriodType: TRoyaltyPeriodType;
	year: number;
};
export const getReportPeriodByPeriod = async (values: TPeriodParams): Promise<TReportPeriod> => {
	const { data } = await axios.get<TReportPeriod>('/report-periods', {
		params: values,
	});
	return data;
};
