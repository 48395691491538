import Preloader from 'shared/ui/preloader';

import './styles.scss';

const WaitingMessage = () => {
	return (
		<div className="waiting-message">
			<div className="waiting-message__icon">
				<Preloader size={20} />
			</div>
			<div className="waiting-message__text">
				Generating statements. You will be notified when the process is complete.
			</div>
		</div>
	);
};

export default WaitingMessage;
