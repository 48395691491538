import { FC, ReactNode } from 'react';

import styles from './styles.module.scss';

type TProps = {
	children: ReactNode;
};

const NavigationItem: FC<TProps> = (props) => {
	const { children } = props;

	return <li className={styles.item}>{children}</li>;
};

export default NavigationItem;
