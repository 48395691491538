export const currencies = [
	{
		value: 'USD',
		label: 'USD',
	},
	{
		value: 'EUR',
		label: 'EUR',
	},
	{
		value: 'GBP',
		label: 'GBP',
	},
];

export const types = [
	{
		value: 'ALL_COPIES',
		label: 'All copies',
	},
	{
		value: 'NET_RECEIPT',
		label: 'Net receipt',
	},
];

export const binding = [
	{
		value: 'PAPERBACK',
		label: 'Paperback',
	},
	{
		value: 'HARDBACK',
		label: 'Hardback',
	},
	{
		value: 'TRADE',
		label: 'Trade',
	},
	{
		value: 'POCKETBOOK',
		label: 'Pocketbook',
	},
	{
		value: 'BOOKCLUB',
		label: 'Bookclub',
	},
	{
		value: 'AUDIOBOOK',
		label: 'Audiobook',
	},
	{
		value: 'EBOOK',
		label: 'Ebook',
	},
];

export const deliveryList = [
	{
		value: 'FOB',
		label: 'FOB',
	},
	{
		value: 'CIF',
		label: 'CIF',
	},
	{
		value: 'DAP',
		label: 'DAP',
	},
];

export const periodTypes = {
	FIRST: 'January - June',
	SECOND: 'July - December',
};

export const SIZE = 20;

export const monthsAsObj = {
	JANUARY: 0,
	FEBRUARY: 1,
	MARCH: 2,
	APRIL: 3,
	MAY: 4,
	JUNE: 5,
	JULY: 6,
	AUGUST: 7,
	SEPTEMBER: 8,
	OCTOBER: 9,
	NOVEMBER: 10,
	DECEMBER: 11,
};
