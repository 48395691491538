import { useMutation, useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';

import { cloudFilesKeyStore, getCloudFileReportById } from 'shared/api/cloud-files';
import { getReportPeriodByPeriod, reportPeriodsKeyStore } from 'shared/api/report-periods';
import { TRoyaltyPeriodType } from 'shared/api/reports';
import { periodTypes } from 'shared/constants';
import { normalizeErrorResponse } from 'shared/lib';

import './styles.scss';

type TProps = {
	year: string;
	royaltyPeriodType: TRoyaltyPeriodType;
	closeToast: () => void;
};

const CompletedMessage = (props: TProps) => {
	const { royaltyPeriodType, year, closeToast } = props;

	const { data, isFetching } = useQuery({
		queryKey: [reportPeriodsKeyStore.reportPeriod.getReportPeriod({ year, royaltyPeriodType })],
		queryFn: () => getReportPeriodByPeriod({ year, royaltyPeriodType }),
		enabled: !!(year && royaltyPeriodType),
	});

	const cloudFileId = data?.cloudFileId;

	const { mutateAsync: getFileUrl } = useMutation({
		mutationKey: [cloudFilesKeyStore.cloudFile.getCloudFile(cloudFileId)],
		mutationFn: () => getCloudFileReportById(cloudFileId),
	});

	const handlerDownload = async (e): Promise<void> => {
		try {
			e.preventDefault();
			if (cloudFileId) {
				const response = await getFileUrl();
				closeToast();
				window.open(response.url, '_blank');
			}
		} catch (e) {
			const message = normalizeErrorResponse(e);
			toast.error(message || 'Something went wrong');
		}
	};

	const renderLink = () => {
		if (!cloudFileId) {
			return null;
		}
		return (
			<button type="button" onClick={handlerDownload} className="completed-message__link link">
				Download {year} {periodTypes[royaltyPeriodType]}
			</button>
		);
	};

	return (
		<div className="completed-message__body">
			<div className="completed-message__text">Statement generation completed. {renderLink()}</div>
		</div>
	);
};

export default CompletedMessage;
