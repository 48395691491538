import { ComponentType } from 'react';

import Button from 'shared/ui/button';

import './styles.scss';

interface FallbackProps {
	error: Error;
	resetErrorBoundary: (...args: Array<unknown>) => void;
}
const ErrorFallback: ComponentType<FallbackProps> = ({ error, resetErrorBoundary }) => {
	return (
		<div role="alert" className="error-fallback">
			<span className="error-fallback__title">Something went wrong:</span>
			<pre>{error.message}</pre>
			<Button onClick={resetErrorBoundary}>Try again</Button>
		</div>
	);
};

export default ErrorFallback;
