import React, { ReactNode } from 'react';

import { useQuery } from '@tanstack/react-query';
import { Navigate } from 'react-router-dom';

import { getMe, usersKeyStore } from '../shared/api/users';
import PageLoader from '../shared/ui/page-loader';

import { urlBuilder } from './index';

type TProps = {
	children: ReactNode;
};
const ProtectedRoute = ({ children }: TProps) => {
	const { data, isLoading } = useQuery({
		queryKey: [usersKeyStore.user.me],
		queryFn: getMe,
	});

	const isAdmin = data?.data?.authority === 'ROLE_ADMIN';

	if (isLoading) {
		return <PageLoader />;
	}

	if (!isAdmin) {
		return <Navigate to={urlBuilder('distributors_reports')} replace />;
	}

	return children;
};

export default ProtectedRoute;
