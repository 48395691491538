import { createQueryKeyStore } from '@lukemorales/query-key-factory';
import { AxiosResponse } from 'axios';

import axios, { API_URL } from 'app/axios';

export const usersKeyStore = createQueryKeyStore({
	user: {
		logout: null,
		me: null,
	},
});

export type TMe = {
	authority: UserRoles;
	email: string;
	firstName: string;
	id: number;
	lastName: string;
};

type UserRoles =
	| 'ROLE_ADMIN'
	| 'ROLE_EDITOR'
	| 'ROLE_MARKETING'
	| 'ROLE_PRODUCTION'
	| 'ROLE_SALES'
	| 'ROLE_USER';

export const getMe = async (): Promise<AxiosResponse<TMe>> => {
	const url = API_URL.replace('royalties', 'users/me');
	return axios.get<TMe>(url);
};

type TLogout = {
	targetUrl: string;
};

export const logout = async (): Promise<AxiosResponse<TLogout>> => {
	return axios.get<TLogout>(`${process.env.REACT_APP_API_GETAWAY}/auth/logout`);
};
