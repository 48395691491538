import _ from 'lodash';
import flatten from 'lodash/flatten';
import isPlainObject from 'lodash/isPlainObject';
import values from 'lodash/values';

export const fileName = (title?: string, prefix = 'Contract'): string => {
	if (title) {
		return `${title}-${prefix}.pdf`;
	}
	return `${prefix}.pdf`;
};

export const isEmpty = (value): boolean =>
	value === undefined ||
	value === null ||
	value === '' ||
	(typeof value === 'string' && value.trim().length === 0) ||
	(_.isPlainObject(value) && Object.keys(value).length === 0) ||
	(Array.isArray(value) && value.length === 0);

export const filterEmptyValues = (data: any) => {
	if (typeof data === 'string' || typeof data === 'number') {
		return data;
	}
	return Object.keys(data).reduce((previousValue, key) => {
		const value = data[key];
		if (isEmpty(value)) {
			return previousValue;
		}
		if (_.isPlainObject(value)) {
			const result = filterEmptyValues(value);
			if (isEmpty(result)) {
				return previousValue;
			}
			return {
				...previousValue,
				[key]: result,
			};
		}
		if (Array.isArray(value)) {
			const result = value.map((item) => filterEmptyValues(item)).filter((item) => !isEmpty(item));
			if (isEmpty(result)) {
				return previousValue;
			}
			return {
				...previousValue,
				[key]: result,
			};
		}
		return {
			...previousValue,
			[key]: value,
		};
	}, {});
};

// old function
export const formatDate = (value) => {
	const pattern = /(\d{2})\.(\d{2})\.(\d{4})/;
	const isCorrect = pattern.test(value);
	if (value === null) {
		return;
	}
	if (isCorrect) {
		return value;
	}
	return new Date(value).toLocaleDateString('en-GB').split('/').join('.');
};
export const constructorContractSubmitData = (d: { [key: string]: any }, arrContacts, edition) => {
	const retailPrice = () => {
		if (d.retailPrice) {
			const currency = d?.retailPrice?.currency ? { currency: d.retailPrice.currency } : {};

			if (d.retailPrice.type === 'RANGE') {
				return {
					retailPrice: {
						initialValue: d.retailPrice.value.from,
						finalValue: d.retailPrice.value.to,
						...currency,
					},
				};
			}
			return {
				retailPrice: {
					initialValue: d.retailPrice.initialValue,
					...currency,
				},
			};
		}
		return {};
	};

	const { contacts, ...otherValues } = d;

	if (otherValues?.license?.publicationDate) {
		otherValues.license.publicationDate = formatDate(otherValues.license.publicationDate);
	}

	if (otherValues?.delivery?.booksHandoverDate) {
		otherValues.delivery.booksHandoverDate = formatDate(otherValues.delivery.booksHandoverDate);
	}

	return {
		...otherValues,
		...retailPrice(),

		base: {
			...otherValues.base,
			contactIds: arrContacts?.map((c) => c.contact.id),
			editionId: edition?.editionId?.id || edition?.id,
		},
	};
};

export const normalizeErrorResponse = (error) => {
	const errors = error.error;
	if (error.status === 500) {
		return 'Something went wrong, please try again.';
	} else if (errors instanceof Array) {
		return errors[0];
	} else if (isPlainObject(errors)) {
		const message = flatten(values(errors))[0];
		if (typeof message === 'string') {
			return message;
		} else {
			return message[Object.keys(message)[0]];
		}
	} else if (error?.response?.data) {
		if (error?.response?.data?.violations?.length) {
			return error.response.data.violations
				.map((item) => `${item.fieldName}: ${item.message}`)
				.join('\n');
		}
		return error.response.data;
	}

	return JSON.stringify(errors);
};

export const collectKeys = (obj, parentKey = '') => {
	for (const key in obj) {
		if (obj.hasOwnProperty(key)) {
			if (key === 'ref') {
				return parentKey;
			} else {
				const currentKey = parentKey ? `${parentKey}.${key}` : key;
				if (typeof obj[key] === 'object') {
					const recursiveResult = collectKeys(obj[key], currentKey);
					if (recursiveResult) {
						return recursiveResult;
					}
				}
			}
		}
	}
	return null;
};

export const objectToSelectData = (obj) => {
	if (!obj) {
		return [];
	}

	return Object.keys(obj).map((key) => ({
		value: key.toString(),
		label: obj[key],
	}));
};

// serialize object to query string
export const serializeObjToQuery = (obj: Record<string, any>): string => {
	const str = [];
	for (const key in obj) {
		if (obj.hasOwnProperty(key) && obj[key] !== null && obj[key] !== undefined) {
			const encodedKey = encodeURIComponent(key);
			const encodedValue = encodeURIComponent(obj[key]);
			str.push(`${encodedKey}=${encodedValue}`);
		}
	}
	return str.join('&');
};
